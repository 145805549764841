import { RiSparkling2Fill } from "@remixicon/react";
import styles from "./magicSearchResultSkeleton.module.scss";
import clsx from "clsx";
import dictionary from "@/lib/dictionary";

const MagicSearchResultSkeleton = ({
  question,
  resultNumber,
}: {
  question: string;
  resultNumber: number;
}) => {
  return (
    <div className={styles.searchResultContainer}>
      <div className={styles.resultIdentifierContainer}>
        <p className={styles.resultIdentifier}>{dictionary.magicSearchResult}:</p>
        <div className={styles.resultIdentifierIcon}>{resultNumber}</div>
      </div>

      <div className={styles.searchResultContentWrapper}>
        <div className={styles.searchResultQuestionContent}>
          <p className={styles.originalQuestion}>{question}</p>
          <div className={styles.questionAnswer}>
            <RiSparkling2Fill size={18} />
            <div className={styles.textSkeletonContainer}>
              <div className={styles.textSkeleton}></div>
              <div className={styles.textSkeleton}></div>
              <div className={clsx(styles.textSkeleton, styles.small)}></div>
            </div>
          </div>
        </div>

        <p className={styles.smallText}>{dictionary.magicSearchPages}</p>
        <div className={styles.pagesSkeletonContainer}>
          <div className={styles.pageSkeleton}></div>
          <div className={styles.pageSkeleton}></div>
        </div>
      </div>
    </div>
  );
};

export default MagicSearchResultSkeleton;
