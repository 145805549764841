import dictionary from "@/lib/dictionary";
import { shouldLogoFallback } from "@/lib/logo";
import styles from "./ChatHeader.module.scss";
interface IProps {
  online: boolean;
  clearChat: () => void;
}

const ChatHeader = ({ online, clearChat }: IProps) => {
  return (
    <div className={styles.header}>
      <div className={styles["info-wrapper"]}>
        <div className={styles["logo-wrapper"]}>
          <div
            className={`${styles.logo} ${
              shouldLogoFallback ? styles.fallback : ""
            }`}
            style={{
              backgroundImage: shouldLogoFallback
                ? ""
                : `url([({profile-icon})])`,
              maskImage: shouldLogoFallback ? `url([({profile-icon})])` : "",
              WebkitMaskImage: shouldLogoFallback
                ? `url([({profile-icon})])`
                : "",
            }}
          />
        </div>
        <div className={styles.info}>
          <p className={styles.name}>
            {import.meta.env.DEV ? "Eloquent" : "[({chatbot-name})]"}
          </p>
          <div className={styles.status}>
            <div
              className={`${styles["status-color"]} ${
                styles[online ? "online" : ""]
              }`}
            ></div>
            <p className={styles["status-text"]}>
              {online ? dictionary.online : dictionary.offline}
            </p>
          </div>
        </div>
      </div>
      <button
        style={{ all: "unset", display: "flex" }}
        onClick={() => clearChat()}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M0.888916 8.65971C0.888916 4.98166 3.86285 2 7.55632 2H16.4437C20.126 2 23.1111 4.9942 23.1111 8.65971V22H7.55632C3.87402 22 0.888916 19.0058 0.888916 15.3403V8.65971ZM20.8889 19.7778V8.65971C20.8889 6.2171 18.8944 4.22222 16.4437 4.22222H7.55632C5.094 4.22222 3.11114 6.20509 3.11114 8.65971V15.3403C3.11114 17.7829 5.10572 19.7778 7.55632 19.7778H20.8889Z"
            fill="#F5F5F7"
          />
          <path d="M11 11V8H13V11H16V13H13V16H11V13H8V11H11Z" fill="#F5F5F7" />
        </svg>
      </button>
    </div>
  );
};

export default ChatHeader;
