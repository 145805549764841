import Chat from "./components/Chat";
import MagicSearch from "./components/magicSearch/MagicSearch";

const App = ({
  setLocation,
  magicSearch = false,
}: {
  setLocation?: boolean;
  magicSearch: boolean;
}) => {
  if (magicSearch) {
    return <MagicSearch />;
  }
  return <Chat setLocation={setLocation} />;
};

export default App;
