
/**
 * custom parser to parse data stream which allows us to use experimental_streamData from vercel AI
 * @param data 
 */
export function streamparser(data: string) {
  // Split the input data by newline character
  const parts = data.split("\n");

  let jsonData = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const annotations: any[] = [];
  let content = "";

  // Iterate through each part and process accordingly
  parts.forEach((part) => {
    // Check if the part starts with a number followed by ":"
    const match = part.match(/^(\d+):(.*)$/);
    if (match) {
      const number = parseInt(match[1]);
      let value = match[2].trim();

      // Check the number and assign to respective variable
      if (number === 2) {
        // Parse JSON if number is 2
        jsonData = JSON.parse(value);
      } else if (number === 8) {
        // Parse JSON and push to annotations array if number is 8
        annotations.push(JSON.parse(value));
      } else if (number === 0) {
        // Concatenate strings if number is 0
        // Remove surrounding quotes and unescape escaped characters
        value = value.replace(/\\"/g, '"').replace(/\\\\/g, "\\");
        content += value;
      }
    }
  });

  // Replace escaped newline characters with actual newline characters
  content = content.replace(/\\n/g, "\n");

  // Remove all double quotes from the content string
  content = content.replace(/"/g, "");

  return {
    data: jsonData,
    annotations: annotations[0][0].links,
    content: content,
  };
}
