import dictionary from "@/lib/dictionary";
import { CreateMessage } from "ai";
import { useEffect } from "react";
import AssistantMessage from "./AssistantMessage";
import styles from "./ChatMessages.module.scss";
import LoadingMessage from "./LoadingMessage";
import Suggestions from "./Suggestions";
import UserMessage from "./UserMessage";

interface IProps {
  messages: CreateMessage[];
  loadingResponse: boolean;
  online: boolean;
  onMessageClick: (message: string) => void;
  setShowDisclaimer: (show: boolean) => void;
}

const ChatMessages = ({
  online,
  messages,
  loadingResponse,
  onMessageClick,
  setShowDisclaimer,
}: IProps) => {
  useEffect(() => {
    const chat = document.querySelector(`.${styles.messages}`);

    if (chat) chat.scrollTo(0, chat.scrollHeight);
  }, [messages]);

  messages = [
    {
      id: "init",
      role: "assistant",
      content: import.meta.env.DEV
        ? "Hallo, Ik ben Elo, hoe kan ik je helpen?"
        : "[({intro-message})]",
    },
    ...messages,
  ];

  return (
    <div className={styles.container}>
      <div className={styles.messages}>
        {!online && (
          <div className={styles.offline}>
            <p>{dictionary.eloquentOffline}</p>
          </div>
        )}
        {online && (
          <>
            <p className={styles.disclaimer}>
              {dictionary.disclaimer}
              <span
                onClick={() => setShowDisclaimer(true)}
                className={`${styles.link} ${styles.disclaimer}`}
              >
                {dictionary.readDisclaimer}
              </span>
            </p>
            {messages.map((message) => {
              if (message.role === "assistant")
                return <AssistantMessage key={message.id} message={message} />;
              if (message.role === "user")
                return <UserMessage key={message.id} message={message} />;
            })}
          </>
        )}
        {online && loadingResponse && <LoadingMessage key="loading" />}
        {online && (
          <Suggestions
            onMessageClick={onMessageClick}
            shown={messages.length <= 1}
          />
        )}
      </div>
    </div>
  );
};

export default ChatMessages;
