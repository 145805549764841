/**
 * function to convert string (url) to an object which is used to render the links in magicSearch
 */
export const convertLink = ({ link }: { link: string }) => {
  try {
    const l = new URL(link);
    let hostname = l.hostname;

    if (hostname.startsWith("www.")) {
      hostname = hostname.substring(4);
    }

    return {
      domain: hostname,
      faviconUrl: `https://s2.googleusercontent.com/s2/favicons?domain=${l.origin}`,
      href: l.href,
      title: l.pathname !== "/" ? l.pathname.substring(1) : l.host, //remove slash at start of pathname
    };
  } catch (error) {
    return null;
  }
};
