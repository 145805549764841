import dictionary from "@/lib/dictionary";
import { voiceAssistant, whiteLabeling } from "@/lib/settings";
import { baseUrl } from "@/lib/url";
import { RiMic2Fill, RiSendPlane2Fill } from "@remixicon/react";
import styles from "./ChatFooter.module.scss";

interface IProps {
  setDisplayAudio: (displayAudio: boolean) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  loading: boolean;
  online: boolean;
}

const ChatFooter = ({
  setDisplayAudio,
  online,
  onSubmit,
  onChange,
  value,
  loading,
}: IProps) => {
  return (
    <div className={styles.footer}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          !loading && onSubmit(e);
        }}
        className={styles.input}
      >
        <input
          value={!online ? "" : value}
          onChange={onChange}
          type="text"
          disabled={!online}
          placeholder={
            !online
              ? dictionary.eloquentOffline
              : loading
              ? dictionary.responding
              : dictionary.askSomething
          }
        />
        {!loading && (
          <div className={styles.buttons}>
            {value ? (
              <button type="submit" className={styles.send}>
                <RiSendPlane2Fill size={20} />
              </button>
            ) : (
              voiceAssistant && (
                <button
                  onClick={() => online && setDisplayAudio(true)}
                  className={styles.voice}
                >
                  <RiMic2Fill size={20} />
                </button>
              )
            )}
          </div>
        )}
      </form>
      <a
        href="[({powered-by-url})]"
        target="_blank"
        className={`${styles.powered} ${
          whiteLabeling ? styles["white-labeling"] : ""
        }`}
      >
        Powered by
        {!whiteLabeling ? (
          <span className={styles.highlight}>
            <span
              className={styles.text}
              style={{
                maskImage: `url(${baseUrl}/icons/eloquent_text.svg)`,
                WebkitMaskImage: `url(${baseUrl}/icons/eloquent_text.svg)`,
              }}
            />
          </span>
        ) : (
          " [({white-labeling-name})]"
        )}
      </a>
    </div>
  );
};

export default ChatFooter;
