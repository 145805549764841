import { useEffect, useState } from "react";

export const useOnMobile = () => {
  const [onMobile, setOnMobile] = useState<boolean>(false);

  const checkMobile = () => {
    setOnMobile(window.innerWidth < 500);
  };

  useEffect(() => {
    checkMobile();

    window.addEventListener("resize", checkMobile);

    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  return onMobile;
};
