import { useEffect, useState } from "react";

export const useOnline = () => {
  const [online, setOnline] = useState<boolean>(false);

  const checkOnline = () => {
    fetch(
      import.meta.env.DEV
        ? "http://localhost:3000/api/chat?domain-identifier=dev"
        : "[({production-url})]/api/chat?domain-identifier=[({identifier})]"
    )
      .then((res) => res.json())
      .then((data) => {
        setOnline(data.active);
      })
      .catch(() => {
        setOnline(false);
      });
  };

  useEffect(() => {
    checkOnline();

    const interval = setInterval(() => {
      checkOnline();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return online;
};

export default useOnline;
