import styles from "./ChatMessages.module.scss";

const LoadingMessage = () => {
  return (
    <div className={styles["assistant-message"]}>
      <div className={styles.loading}>
        <div className={styles.dot}></div>
        <div className={styles.dot}></div>
        <div className={styles.dot}></div>
      </div>
    </div>
  );
};

export default LoadingMessage;
