import { Message } from "ai/react";
import styles from "./MagicSearchResult.module.scss";
import { RiSparkling2Fill } from "@remixicon/react";
import Markdown from "react-markdown";
import { streamparser } from "@/lib/streamParser";
import { useEffect, useState } from "react";
import dictionary from "@/lib/dictionary";
import { JSONValue } from "ai";
import { convertLink } from "@/lib/magicSearchLinkConverter";

const SearchResultPage = ({
  title,
  href,
  domain,
  faviconUrl,
}: {
  title: string;
  domain: string;
  href: string;
  faviconUrl: string;
}) => {
  return (
    <a href={href} target="_blank" className={styles.searchResultPage}>
      <p>{title}</p>
      <div className={styles.searchResultContent}>
        <img
          style={{ width: 16, height: 16, borderRadius: "50%" }}
          src={faviconUrl}
        />
        <p>{domain}</p>
      </div>
    </a>
  );
};

const MagicSearchResult = ({
  message,
  messages,
  originalQuestion,
  questionNumber,
  setMessages,
  isLoading,
}: {
  message: Message;
  originalQuestion: string;
  questionNumber: number;
  messages: Message[];
  setMessages: (messages: Message[]) => void;
  isLoading: boolean;
}) => {
  const [parsedMessage, setParsedMessage] = useState<Message>();
  const [replacedMessage, setReplacedMessage] = useState<boolean>(false);


  useEffect(() => {
    //If the message does not contain annotations we need to parse the message using the custom streamparser.
    if (!message.annotations) {
      //parse message and then set the parsedMessage state.
      const { annotations: ann, content: cnt } = streamparser(message.content);
      const msg: Message = {
        id: message.id,
        content: cnt,
        annotations: ann,
        role: message.role,
      };
      setParsedMessage(msg);
    } else {
      //Check if the message.annotations array does not only exists of strings.
      //(which means that annotations array does not contain the correct format to be used in rendering the links)
      if (!message.annotations.every((item) => typeof item === "string")) {
        const annotations = message.annotations[0] as { links: string[] };
        const msg: Message = {
          id: message.id,
          content: message.content,
          annotations: annotations.links,
          role: message.role,
        };

        setParsedMessage(msg);
      } else {
        setParsedMessage(message);
      }
    }
  }, [message]);

  //After the message is completed and the message is parsed.
  //Replace the messages array so it includes the parsed message.
  useEffect(() => {
    if (
      !isLoading &&
      parsedMessage &&
      messages[messages.length - 1].id === parsedMessage.id &&
      !replacedMessage
    ) {
      const msgs = [...messages.slice(0, -1)];
      setMessages([...msgs, parsedMessage]);
      setReplacedMessage(true);
    }
  }, [isLoading, parsedMessage, setMessages, messages, replacedMessage]);

  return (
    <div className={styles.searchResultContainer}>
      <div className={styles.resultIdentifierContainer}>
        <p className={styles.resultIdentifier}>
          {dictionary.magicSearchResult}:
        </p>
        <div className={styles.resultIdentifierIcon}>{questionNumber}</div>
      </div>

      <div className={styles.searchResultContentWrapper}>
        <div className={styles.searchResultQuestionContent}>
          <p className={styles.originalQuestion}>{originalQuestion}</p>
          <div className={styles.questionAnswer}>
            <RiSparkling2Fill size={18} />

            <Markdown
              className={styles.questionAnswerText}
              components={{
                a: ({ href, children }) => (
                  <a href={href} target="_blank">
                    {children}
                  </a>
                ),
              }}
            >
              {parsedMessage?.content}
            </Markdown>
          </div>
        </div>

        {parsedMessage &&
        parsedMessage.annotations &&
        parsedMessage.annotations.length > 0 ? (
          <>
            <p className={styles.smallText}>{dictionary.magicSearchPages}</p>
            <div className={styles.searchResultPagesContainer}>
              {parsedMessage.annotations.map(
                (link: JSONValue, index: number) => {
                  if (typeof link === "string") {
                    const parsedLink = convertLink({ link });

                    return parsedLink ? (
                      <SearchResultPage
                        key={`${index}-${link}`}
                        title={parsedLink.title ?? ""}
                        href={parsedLink.href ?? ""}
                        domain={parsedLink.domain}
                        faviconUrl={parsedLink.faviconUrl}
                      />
                    ) : (
                      <></>
                    );
                  }
                }
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default MagicSearchResult;
