import { CreateMessage } from "ai";

import styles from "./ChatMessages.module.scss";

interface IProps {
  message: CreateMessage;
}

const UserMessage = ({ message }: IProps) => {
  return (
    <div className={styles["user-message"]}>
      <p>{message.content}</p>
    </div>
  );
};

export default UserMessage;
