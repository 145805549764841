import { RiSearch2Line, RiSparkling2Fill } from "@remixicon/react";
import styles from "./MagicSearchTrigger.module.scss";

const MagicSearchTrigger = ({
    isMagicSearchOpen,
  setIsMagicSearchOpen,
}: {
    isMagicSearchOpen: boolean;
  setIsMagicSearchOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <button
      onClick={() => setIsMagicSearchOpen(!isMagicSearchOpen)}
      className={styles.magicSearchButton}
    >
      <RiSparkling2Fill size={20} className={styles.primaryFilled} />
      Smart search
      <RiSearch2Line size={20} />
    </button>
  );
};
export default MagicSearchTrigger;
