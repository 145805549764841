import ReactDOM from "react-dom";
import App from "./App.tsx";

import "@/assets/fonts.scss";
import "./index.css";
import { magicSearch, manualRendering } from "./lib/settings.ts";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://2bf515d3ecbdb576ec04ba92a58f83d4@o292676.ingest.us.sentry.io/4507582968365056",
  integrations: [Sentry.replayIntegration()],
  defaultIntegrations: false,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const el = document.createElement("script");
el.setAttribute("src", "https://analytics.savvy.codes/script.js");
el.setAttribute("data-website-id", "4b34c36c-9a83-46c6-9f06-d0a4e7677912");
el.setAttribute("data-auto-track", "false");
document.body.appendChild(el);

// eslint-disable-next-line react-refresh/only-export-components
const Eloquent = {
  render: (el: HTMLElement, setLocation?: boolean) => {
    try {
      ReactDOM.render(
        <App setLocation={setLocation} magicSearch={magicSearch === "true"} />,
        el
      );
    } catch (error) {
      Sentry.captureException(error);
    }
  },
  remove: (el: HTMLElement) => {
    ReactDOM.unmountComponentAtNode(el);
  },
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.Eloquent = Eloquent;

if (!manualRendering) {
  window.addEventListener("load", () => {
    const element = document.createElement("div");
    element.id = "eloquent-chatbot-fixed";
    document.body.appendChild(element);
    Eloquent.render(element);
  });
} else {
  const element = document.getElementById("eloquent-manual-rendering");
  if (element) Eloquent.render(element);
}
