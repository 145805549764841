import styles from "./Waveform.module.scss";
import { useEffect, useRef, useState } from "react";

interface IProps {
  disableLoading?: boolean;
  source?: ArrayBuffer | null;
  mic?: boolean;
  size?: number;
}

const Waveform = ({ disableLoading, source, mic, size = 16 }: IProps) => {
  const [hasAudioEnded, setHasAudioEnded] = useState<Boolean>(false);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const audioContext = new window.AudioContext();
  let analyser: AnalyserNode;
  let sourceNode: AudioBufferSourceNode | MediaStreamAudioSourceNode;
  let count = 0;

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const dpi = window.devicePixelRatio;
    const styleHeight = +getComputedStyle(canvas).getPropertyValue("height").slice(0, -2);
    const styleWidth = +getComputedStyle(canvas).getPropertyValue("width").slice(0, -2);

    canvas.height = styleHeight * dpi;
    canvas.width = styleWidth * dpi;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    ctx.scale(dpi, dpi);

    const initializeAnalyser = () => {
      analyser = audioContext.createAnalyser();
      analyser.fftSize = 256;

      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      const draw = () => {
        if (source || mic) {
          analyser.getByteFrequencyData(dataArray);

          if (hasAudioEnded && dataArray.every((value) => value === 0)) setHasAudioEnded(false);
        }

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        const centerX = canvas.width / (2 * dpi);
        const centerY = canvas.height / (2 * dpi);
        const radius = size;
        const r = radius / 2;
        const increaseMultiplier = mic ? 0.1 : 0.2;
        const distanceBetweenCircles = 1.5 * radius;

        const position = [
          centerX - 2 * distanceBetweenCircles - r,
          centerX - distanceBetweenCircles - r,
          centerX - r,
          centerX + distanceBetweenCircles - r,
          centerX + 2 * distanceBetweenCircles - r,
        ];

        count++;

        for (let i = 0; i < 5; i++) {
          const x = position[i];
          const y = centerY - r - (dataArray[i] * increaseMultiplier) / 2;

          ctx.beginPath();
          ctx.roundRect(x, y, radius, Math.max(radius + dataArray[i] * increaseMultiplier), 10);
          ctx.fillStyle = `rgba(255, 255, 255, ${
            source || mic ? 1 : disableLoading ? 0 : Math.max(Math.sin(count / 40) * 0.4)
          })`;
          ctx.fill();
          ctx.closePath();
        }

        requestAnimationFrame(draw);
      };

      draw();
    };

    const startSource = async () => {
      try {
        if (mic) {
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          sourceNode = audioContext.createMediaStreamSource(stream);
        } else if (source) {
          const audioBuffer = await audioContext.decodeAudioData(source);
          sourceNode = audioContext.createBufferSource();
          sourceNode.buffer = audioBuffer;

          if (sourceNode instanceof AudioBufferSourceNode) {
            sourceNode.start();

            sourceNode.onended = () => {
              setHasAudioEnded(true);
            };
          }
        }

        if (sourceNode) {
          sourceNode.connect(analyser);
          if (!mic) {
            analyser.connect(audioContext.destination);
          }
        }
      } catch (error) {
        console.error("Error accessing source:", error);
      }
    };

    initializeAnalyser();
    startSource();

    return () => {
      audioContext.close();
    };
  }, [audioContext, source, mic]);

  return (
    <div className={styles.container}>
      <canvas ref={canvasRef} style={{ width: "300px", height: "300px" }} />
    </div>
  );
};

export default Waveform;
