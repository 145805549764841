import { MouseEventHandler, ReactNode } from "react";
import styles from "./IconButton.module.scss";

const IconButton = ({
  children,
  title,
  onClick,
}: {
  children: ReactNode;
  title?: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
}) => {
  return (
    <button className={styles.iconButtonContainer} onClick={onClick}>
      <div className={styles.iconButton}>{children}</div>
      {title ? <span>{title}</span> : <></>}
    </button>
  );
};

export default IconButton;
