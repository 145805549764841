export default {
  eloquentOffline: import.meta.env.DEV
    ? "Eloquent is offline"
    : "[({eloquent-offline})]",
  offline: import.meta.env.DEV ? "Offline" : "[({offline})]",
  online: import.meta.env.DEV ? "Online" : "[({online})]",
  popupMessage: import.meta.env.DEV ? "Popup message" : "[({popup-message})]",
  responding: import.meta.env.DEV ? "Responding..." : "[({responding})]",
  askSomething: import.meta.env.DEV
    ? "Ask a question..."
    : "[({ask-something})]",
  pushToTalk: import.meta.env.DEV ? "Push to talk" : "[({push-to-talk})]",
  loading: import.meta.env.DEV ? "Loading..." : "[({loading})]",
  tapToInterrupt: import.meta.env.DEV
    ? "Tap to interrupt"
    : "[({tap-to-interrupt})]",
  disclaimer: import.meta.env.DEV
    ? "Eloquent can make mistakes. Consider checking important information."
    : "[({disclaimer})]",
  magicSearchPlaceholder: import.meta.env.DEV
    ? "Search for anything, documents, actions, contacts"
    : "[({magic-search-placeholder})]",
  magicSearchFollowUp: import.meta.env.DEV
    ? "Ask a follow-up"
    : "[({magic-search-follow-up})]",
  readDisclaimer: import.meta.env.DEV
    ? " Read our disclaimer."
    : "[({read-disclaimer})]",
  detailedDisclaimer: import.meta.env.DEV
    ? "This is a detailed disclaimer."
    : "[({detailed-disclaimer})]",
  closeMagicSearch: import.meta.env.DEV ? "Close" : "[({close-magic-search})]",
  newMagicSearch: import.meta.env.DEV ? "New search" : "[({new-magic-search})]",
  magicSearchPages: import.meta.env.DEV ? "Pages" : "[({magic-search-pages})]",
  magicSearchResult: import.meta.env.DEV
    ? "Result"
    : "[({magic-search-result})]",
  automaticMessage: import.meta.env.DEV
    ? "Ik wil graag meer weten over deze vacature"
    : "[({automatic-message})]",
};
