import React, { useState } from "react";
import MagicSearchTrigger from "./MagicSearchTrigger";
import { MagicSearchBar } from "./MagicSearchBar";
import styles from "./magicSearch.module.scss";
import ReactDOM from "react-dom";

const MagicSearch = () => {
  const [isMagicSearchOpen, setIsMagicSearchOpen] = useState(false);
  return (
    <div className={styles.magicSearch}>
      <MagicSearchTrigger
        setIsMagicSearchOpen={setIsMagicSearchOpen}
        isMagicSearchOpen={isMagicSearchOpen}
      />
      {isMagicSearchOpen && (
        <React.Fragment>
          {ReactDOM.createPortal(
            <MagicSearchBar
              isMagicSearchOpen={isMagicSearchOpen}
              setIsMagicSearchOpen={setIsMagicSearchOpen}
            />,
            document.body
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default MagicSearch;
