import { CreateMessage } from "ai/react";
import styles from "./ChatMessages.module.scss";
import Markdown from "react-markdown";

interface IProps {
  message: CreateMessage;
}

const AssistantMessage = ({ message }: IProps) => {
  return (
    <div className={styles["assistant-message"]}>
      <Markdown
        components={{
          a: ({ href, children }) => (
            <a href={href} target="_blank">
              {children}
            </a>
          ),
        }}
      >
        {message.content}
      </Markdown>
    </div>
  );
};

export default AssistantMessage;
