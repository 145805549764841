import { ChangeEventHandler, FormEventHandler } from "react";
import styles from "./magicSearchBarFollowUp.module.scss";
import { RiSparkling2Fill } from "@remixicon/react";
import dictionary from "@/lib/dictionary";

export const MagicSearchBarFollowUp = ({
  handleSubmit,
  input,
  handleInputChange,
  isLoading,
}: {
  handleSubmit: FormEventHandler<HTMLFormElement>;
  input: string;
  handleInputChange?: ChangeEventHandler<HTMLInputElement>;
  isLoading: boolean;
}) => {
  return (
    <form onSubmit={handleSubmit} className={styles.followUpContainer}>
      <RiSparkling2Fill size={24} className={styles.sparklingIcon} />
      <input
        type="text"
        value={input}
        onChange={handleInputChange}
        disabled={isLoading}
        className={styles.followUp}
        placeholder={dictionary.magicSearchFollowUp}
      />
    </form>
  );
};
