import { Drawer } from "vaul";
import styles from "./MobileWrapper.module.scss";
import { useOnMobile } from "@/hooks/useOnMobile";
import { useScreen } from "@/hooks/useScreen";

interface IProps {
  children: React.ReactNode;
  setChatOpened: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  setLocation?: boolean;
}

const MobileWrapper = ({ setLocation, children, setChatOpened, open }: IProps) => {
  const onMobile = useOnMobile();

  const { width } = useScreen();

  const height = width * 1.6;

  return onMobile && !setLocation ? (
    <Drawer.Root open={open} onOpenChange={setChatOpened} shouldScaleBackground>
      <Drawer.Portal>
        <Drawer.Overlay className={styles.overlay} />
        <Drawer.Content style={{ height: height }} className={styles.content}>
          <div className={styles.bar} />
          {children}
        </Drawer.Content>
        <Drawer.Overlay />
      </Drawer.Portal>
    </Drawer.Root>
  ) : (
    children
  );
};

export default MobileWrapper;
