import styles from "./Suggestions.module.scss";

interface IProps {
  shown: boolean;
  onMessageClick: (message: string) => void;
}

const Suggestions = ({ shown, onMessageClick }: IProps) => {
  const suggestions = import.meta.env.DEV
    ? [
        "Do you really speak over 95 languages?",
        "Explain the pay-per-use model",
        "Can I speak to a human?",
        "Where do I start?",
      ]
    : (JSON.parse(`[({suggestions})]`).filter(
        (s: string) => s
      ) as unknown as string[]);

  return suggestions?.length && shown ? (
    <div className={styles.container}>
      <p className={styles.title}>Suggestions</p>
      {suggestions.map((suggestion, index) => (
        <p
          key={index}
          onClick={() => onMessageClick(suggestion)}
          className={`${styles.item}`}
        >
          {suggestion}
        </p>
      ))}
    </div>
  ) : null;
};

export default Suggestions;
